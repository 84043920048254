/* fonts */
@font-face {
  font-family: 'Pressura_light';
  src: url('/webFonts/ts/GTPressura-Light.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Pressura_regular';
  src: url('/webFonts/ts/GTPressura.woff') format('woff');
  font-style: normal;
}

@font-face {
  font-family: 'Pressura_italic';
  src: url('/webFonts/ts/GTPressura-RegularItalic.woff') format('woff');
  font-style: normal;
}


@font-face {
  font-family: 'Pentameter';
  src: url('/webFonts/ts/Pentameter Condensed Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

:root {
  --blu: #0000FF;;
  --grey: #F5F5F5;
  --fs: 16px;
  --fm: 26px;
  --fxs: 14px;
}

*{
  font-family: 'Pressura_light';
  font-size: var(--fs);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

.sui-layout-body:after {
height: 0
}

.sui-result__language, .sui-result__contentType, .sui-result__publication, .clearFilters_button {
    background: none!important;
    border: none;
    padding: 0!important;
    /*optional*/
    /* font-family: arial, sans-serif; */
    /*input has OS specific font-family*/
    /* color: #069; */
    /* text-decoration: underline; */
    cursor: pointer;

    font-weight: 400;
    font-size: 16px;

    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
    line-height: 1.5;
    color: #525252
  }

  .sui-footer {
    background-color: black;
    text-align: left;
    margin-bottom: 0 !important;
    color: white;
    display: flex;
    align-items: center;
    padding: 20px;
    display: flex;
    justify-content: space-between;
  }

  .clearFilters_outer_div {
    margin-left: auto;
    margin-right: 25px;
  }

  .clearFilters_button  {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
    line-height: 1.5;
    font-weight: 400;
    color: #4a4b4b;
    font-size: 12px;
    display: inline-block;
    text-decoration: underline;
  }

button:focus {
    outline: none; /* Remove the outline */
}

button.active {
  border-style: none; /* Remove the border */
}

body {
  margin: 0;
}

.sui-layout-header {
  padding: 0;
  border-bottom: none;
}

.sui-layout-header__inner {
}



.header_info {
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
}

.header_img_covid {
  height: 150px;
  float:left;
}

.header_img_syllabus_wrapper {
  height: auto;
  float:right;
  margin-top: auto;
  margin-bottom: auto;
}

.header_img_syllabus {
width: 170px
}

.header_text {
  flex: 1 1 auto;
  margin: auto 40px auto 0px;
  color: black;
}

.header_info {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
    line-height: 1.5;
    font-weight: 400;
}

.header_title {
  font-size: 1.5em;
  width: 100%;
  margin: auto;
  font-weight: bold;
}

.header_poweredby {
  font-size: 0.8em;
  text-align: center;
  font-weight: 200;
}

.header_poweredby_syllabus {
  position: relative;
  bottom: 10px;
}


.header_right {
  margin-left: auto;
  display: flex;
  flex-direction:column;
  align-items: flex-end;
  flex-shrink: 0;
}

@media only screen and (max-width: 800px) {
  .header_img_covid {
    height: 80px;
    float:left;
  }

  .header_img_syllabus_wrapper {
    display: none;
  }

  .header_intro {
    display: none;
  }
}


/* newsletter */
.newsletter_container{
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.header_container{
  padding: 20px;
  height: 70px;
  display: flex;
  align-items: center;
}
.header_container--cabinet {
  background-color: black;
  color: white;

  padding-right:  40px;

  width: 100vw;
}
.header_container--cabinet p{
  font-size: 20px;
  text-transform: uppercase;
  font-family: "Pressura_regular";
}
.header_container--cabinet .btn-reverse{
  width:fit-content;
  padding: 5px 15px;
  padding-top: 7px;
}
.header_container--cabinet .header_logo{
  min-width: calc(20vw - 15px);
  box-sizing: border-box;
}
.header_container .header_logo img{
  width: 180px;
}
.header_link_container{
  width:100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btn-reverse{
  font-family: "Pentameter";
  text-transform: uppercase;
  border: 1px solid white;
  width: fit-content;
  padding: 7px 15px 5px;
  font-size: 16px;
}

.sui-search-box{
  height: 70px;
  border-bottom: 1px solid black;
}
.sui-search-box:focus-visible, form:focus-visible{
  outline: none;
}
.sui-search-box input{
  height: 100%;
  font-size: 16px;
  font-family: "Pentameter";
  text-transform: uppercase;
  border: none;
}
.sui-search-box__text-input:focus{
  outline: none;
  border: none;
  box-shadow: none;
}
.sui-search-box__wrapper{
  border-radius: 0px;
}
.sui-search-box__submit:hover{
  box-shadow: none;
  background: unset;
}

.sui-layout-main-body{
  min-height: calc(100vh - 210px);
}

.sui-sorting *{
  font-weight: normal  !important;
}

.sui-search-box__submit{
  display: none;
}

.sui-layout-body__inner{
  max-width: unset;
  padding: 0;
}
.sui-layout-main-body{
  min-height: calc(100vh - 300px);
}

.sui-layout-sidebar{
  width: 20vw;
  border-right: 1px solid black;
  padding: 10px 20px;
  background: white;
  position: sticky;
  top: 0px;
  height: 100vh;
  overflow-y: scroll;
}

.sui-layout-main{
  width:80vw;
  padding: 10px 20px;
  background: white;
}


.sui-result__header{
  padding-left: 0px;
}

.sui-result__title-link{
font-family: "Pressura_regular";
font-size: var(--fm);
color: black;
}
.sui-result__title-link:hover{
 color: var(--blu)
  }

.sui-layout-main-header__inner *{
  font-family: "Pentameter";
  text-transform: uppercase;
  font-style: normal;
  color: black;
}

.sui-facet{
  padding-bottom: 10px;
  border-bottom: 1px dotted black;
}
.sui-facet + .sui-facet {
  margin-top: 20px;
}

/* accordion facet */

.facet_container{
  height: 0px;
  transition: .3s ease;
  overflow: hidden;
}
.facet_container.open{
  height: auto;
}
.sui-facet__title{
  position: relative;
  width:100%;
  cursor: pointer;
}
.sui-facet__title:after{
  content: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline x1='1.06934' y1='8.29297' x2='17.3298' y2='8.29297' stroke='black' stroke-width='0.5' stroke-linecap='round'/%3E%3Cline x1='1.28223' y1='8.2915' x2='17.5426' y2='8.2915' stroke='black' stroke-width='0.5' stroke-linecap='round'/%3E%3Cline x1='9.66211' y1='0.411621' x2='9.66211' y2='16.672' stroke='black' stroke-width='0.5' stroke-linecap='round'/%3E%3C/svg%3E%0A");
  position: absolute;
  right: 0;
  font-family: "Pentameter";
  line-height: 1;
  margin-top: 5px;
}
.sui-facet__title.open:after{
  transform: rotate(45DEG);
}


.sui-sorting__label, .sui-facet__title{
  font-size: 20px;
  color: black;
  font-family: "Pressura_regular";
  margin-bottom: 10px;
}

.sui-multi-checkbox-facet__input-text:hover{
  color: black;
}
.sui-multi-checkbox-facet{
  color: #A4A4A4;
  text-transform: uppercase;
}

.sui-select{
  font-family: "Pressura_light";
  text-transform: uppercase;
 
}

.sui-paging-info{
font-family: "Pentameter";
font-size: 16px;
}
.sui-paging-info strong{
  font-family: "Pentameter";
  font-size: 16px;
  font-weight: normal;
  text-decoration: underline;
}

.sui-result{
  display: flex;
  min-height: 150px;
  align-items: flex-start;
  padding-bottom: 60px;
  border: none;
  border-bottom: 1px dotted black;
  border-radius: 0px;
  box-shadow: none;
}

.sui-result__body{
  width: 75%;
  max-width: 800px;
  padding-left: 20px;
}

.sui-select__control{
  border-radius: 2px;
}

.sui-result_author_col{
  width: 25%;
  padding-top: 5px;
}
.sui-result_author_col span{
  font-size: 15px;
  font-family: "Pressura_regular";
  color: black;
}
.sui-result__publication{
  font-size: 15px;
  font-family: "Pressura_italic";
  color: black;
  text-align: left;
}

.sui-result__description, .sui-result__description span{
  font-size: 16px;
  font-family: "Pressura_light";
}

.sui-multi-checkbox-facet__checkbox{
  display: none;
}
.sui-multi-checkbox-facet__input-text.checked{
  color: black;
}

.sui-multi-checkbox-facet svg{
  height: 20px;
  width: 20px;
  margin-right: 5px;
}

.sui-multi-checkbox-facet svg path{
  stroke: #A4A4A4;
}

.type_icon svg{
  margin-right: 7px;
  height: 23px;
  width:23px;
}

.sui-result + .sui-result{
  margin-top: 0;
}

.sui-results-per-page *{
  font-weight: normal !important;
}



/* mobile */

@media screen and (max-width: 800px){
  .sui-layout-main{
    width:100%;
  }
  .sui-layout-sidebar{
    width: 90vw;
    position: fixed;

  }
  .sui-layout-sidebar-toggle{
    margin-top: 0px;
    font-family: "Pentameter";
    padding: 10px;
    margin: 10px;
    width: calc(100% - 20px);
    text-transform: uppercase;
    color: black;
    border-color: black;
    border-radius: 0px;
    font-weight: normal;
  }

  .sui-layout-sidebar .sui-layout-sidebar-toggle{
    width:100%;
    margin: 0px;
    margin-bottom: 20px;
  }

  .sui-result{
    flex-direction: column;
    padding-bottom: 40px;
  }
  .sui-result_author_col{
    width:100%;
    margin-bottom: 10px;
  }
  .sui-result__body{
    width:100%;
    padding-left: 0;
  }
  .sui-result__title-link{
    font-size: 24px;
    line-height: 1.4;
  }

  .header_container{
    flex-direction: column;
    height: auto;
  }
  .header_link{
    display: none;
  }
  .header_link_container{
    justify-content: center;
  }
  .header_link_container p{
    margin: 0;
  }

  .sui-footer{
    flex-direction: column;
    justify-content: center;
  }
  .sui-footer img{
    margin-bottom: 20px;
  }
}